import axios from 'axios';
import jsonwebtoken from 'jsonwebtoken';

const user = {};
let userId = null;

const getUser = async () => user;

const setHeader = (token) => {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
};

const isLoggedIn = async () => {
  if (!localStorage.access_token) return false;
  const userdata = await getUser();
  return !!userdata;
};

const logout = () => {
  localStorage.removeItem('access_token');
  window.location.href = '/';
};

const getUserId = () => {
  if (!userId && localStorage.access_token) {
    const payload = jsonwebtoken.decode(localStorage.access_token);
    userId = payload.id;
  }
  return userId;
};

function accessType() {
  return 'full';
}

function isAccessFull() {
  return accessType() === 'full';
}
function isSuper() {
  return getUserId() === 'fa44bb46-04e7-4b1c-af7f-786295fb2d9d';
}

function checkAuth() {
  // Logout if expired
  setInterval(() => {
    if (localStorage.access_token) {
      try {
        const payload = jsonwebtoken.decode(localStorage.access_token);
        const expiry = (payload.exp * 1000);
        if (Date.now() > expiry) {
          logout();
        }
      } catch (e) {
        logout();
      }
    }
  }, 1000);
}
checkAuth();

export default {
  isLoggedIn,
  setHeader,
  getUser,
  logout,
  getUserId,
  accessType,
  isAccessFull,
  isSuper,
};
